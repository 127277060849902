<template>
  <div class="page__wrapper">
    <ag-grid
      ref="nvrGrid"
      edit-type="fullRow"
      style="width: 100%; height: 100%"
      :column-defs="columnDefs"
      :row-data.sync="tableData"
      :grid-options="gridOptions"
      :framework-components="frameworkComponents"
    >
      <!-- 查询条件 -->
      <simple-form
        v-model="searchModel"
        :form-field="searchFormField"
      >
        <template slot="tableOperation">
          <el-button
            v-show="editable"
            type="primary"
            @click="handleAddClick"
          >
            {{ $t("operation.create") }}
          </el-button>
          <el-button @click="handleDataSearch">
            {{ $t("operation.search") }}
          </el-button>
        </template>
      </simple-form>
    </ag-grid>
  </div>
</template>

<script>
import Vue from 'vue'

const BASEURL = {
  list: '/jlr/tt/newCarRetailDetails/list',
  save: '/jlr/tt/newCarRetailDetails/save',
  update: '/jlr/tt/newCarRetailDetails/update',
  delete: '/jlr/tt/newCarRetailDetails/delete'
}

/** 自定义操作列 */
const Operation = Vue.extend({
  inject: ['handleDataSearch', 'handleDeleteClick', 'handleSaveClick'],
  template: `<div style="height:100%;text-align:center;">
      <el-tooltip effect="light" :content="$t('operation.save')">
        <el-button type="text" style="font-size:14px;" icon="el-icon-circle-check" @click="handleSaveClick(params)" />
      </el-tooltip>
      <el-tooltip effect="light" style="font-size:14px;" :content="$t('operation.cancel')">
        <el-button type="text" icon="el-icon-refresh-left" @click="handleDataSearch" />
      </el-tooltip>
      <el-tooltip effect="light" style="font-size:14px;" :content="$t('operation.delete')">
        <el-button type="text" icon="el-icon-delete" @click="handleDeleteClick(params.data)" />
      </el-tooltip>
    </div>`
})

export default {
  name: 'NewVehicleRetail',
  props: {
    editable: { type: Boolean, default: false },
    detailData: { type: Object }
  },
  data () {
    return {
      searchModel: {},
      gridOptions: {},
      frameworkComponents: {},
      isShowDetailDialog: false,
      tableData: []
    }
  },
  computed: {
    searchFormField () {
      return [
        // {
        //   prop: 'vin',
        //   type: 'Input',
        //   labelWidth: '50px',
        //   col: { xs: 4, sm: 4, md: 4 },
        //   label: this.$t('dealer_finance.newVehicleNotRetail.vin'),
        //   component: { clearable: true }
        // },
        {
          slotName: 'tableOperation',
          labelWidth: '0',
          col: { xs: 24, sm: 24, md: 24 },
          style: { textAlign: 'right' }
        }
      ]
    },
    columnDefs () {
      return [
        {
          headerName: this.$t('dealer_finance.newVehicleRetail.tableTitle'),
          field: 'tableTitle',
          children: [
            {
              headerName: this.$t('dealer_finance.newVehicleRetail.vin'),
              field: 'vin',
              pinned: 'left',
              // editable: this.editable,
              editable: params => !params.data.id && this.editable,
              cellEditorParams: {
                rules: { required: true, message: this.$t('validate.isRequired') }
              },
              valueFormatter: params => params.value ? params.value.substring(params.value.length - 9, params.value.length) : '',
              minWidth: 200
            },
            {
              headerName: this.$t('dealer_finance.newVehicleRetail.currenPastSales'),
              field: 'salesPeriod',
              editable: this.editable,
              type: 'Select',
              cellEditorParams: {
                componentProp: {
                  optionList: this.$getDictList('tt_sales_period').map(item => {
                    return { value: item.value, label: item.label }
                  })
                }
              },
              valueFormatter: params => this.$getDictLabel({ type: 'tt_sales_period', value: params.value }),
              minWidth: 180
            },
            {
              headerName: this.$t('dealer_finance.newVehicleRetail.vehicleName'),
              field: 'model',
              minWidth: 220
            },
            // {
            //   headerName: this.$t('dealer_finance.newVehicleRetail.purchaseSource'),
            //   field: 'sourcingSources',
            //   editable: this.editable,
            //   type: 'Select',
            //   cellEditorParams: {
            //     componentProp: {
            //       optionList: this.$getDictList('tt_sourcing_sources').map(item => {
            //         return { value: item.value, label: item.label }
            //       })
            //     }
            //   },
            //   valueFormatter: params => this.$getDictLabel({ type: 'tt_sourcing_sources', value: params.value }),
            //   minWidth: 160
            // },
            // {
            //   headerName: this.$t('dealer_finance.newVehicleRetail.allocatingSources'),
            //   field: 'allocationSources',
            //   editable: this.editable,
            //   minWidth: 160
            // },
            {
              headerName: this.$t('dealer_finance.newVehicleRetail.salesCategory'),
              field: 'salesCategory',
              editable: this.editable,
              type: 'Select',
              cellEditorParams: {
                componentProp: {
                  optionList: this.$getDictList('tt_sales_category31').map(item => {
                    return { value: item.value, label: item.label }
                  })
                }
              },
              valueFormatter: params => {
                return this.$getDictLabel({ type: 'tt_sales_category31', value: params.value })
              },
              minWidth: 160
            },
            {
              headerName: this.$t('dealer_finance.newVehicleRetail.nakedVehicleWithoutTax'),
              field: 'income',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
              minWidth: 200
            },
            {
              headerName: this.$t('dealer_finance.newVehicleRetail.newVehicleCost'),
              field: 'cost',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
              minWidth: 200
            },
            {
              headerName: this.$t('dealer_finance.newVehicleRetail.MSRP'),
              field: 'msrp',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
              minWidth: 200
            },
            {
              headerName: this.$t('dealer_finance.newVehicleRetail.grossProfit1'),
              field: 'maoriOne',
              valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
              minWidth: 200
            },
            {
              headerName: this.$t('dealer_finance.newVehicleRetail.VRD'),
              field: 'vdm',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
              minWidth: 200
            },
            {
              headerName: this.$t('dealer_finance.newVehicleRetail.VME'),
              field: 'vme',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
              minWidth: 200
            },
            {
              headerName: this.$t('dealer_finance.newVehicleRetail.grossProfit2'),
              field: 'maoriTwo',
              valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
              minWidth: 200
            }
          ]
        },
        {
          headerName: this.$t('dealer_finance.newVehicleRetail.derivativeBusinessInfo'),
          field: 'derivativeBusinessInfo',
          children: [
            {
              headerName: this.$t('dealer_finance.newVehicleRetail.financialServiceIncome'),
              field: 'financialServicesIncome',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
              minWidth: 220
            },
            {
              headerName: this.$t('dealer_finance.newVehicleRetail.insuranceIncome'),
              field: 'insuranceServicesIncome',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
              minWidth: 220
            },
            {
              headerName: this.$t('dealer_finance.newVehicleRetail.stuffSalesIncome'),
              field: 'vehicleSuppliesIncome',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
              minWidth: 240
            },
            {
              headerName: this.$t('dealer_finance.newVehicleRetail.stuffSalesCost'),
              field: 'vehicleSuppliesCost',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
              minWidth: 240
            },
            {
              headerName: this.$t('dealer_finance.newVehicleRetail.extendWarrantyIncome'),
              field: 'extendedWarrantyIncome',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
              minWidth: 220
            },
            {
              headerName: this.$t('dealer_finance.newVehicleRetail.extendWarrantySales'),
              field: 'extendedWarrantyCost',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
              minWidth: 220
            },
            {
              headerName: this.$t('dealer_finance.newVehicleRetail.otherServiceIncome'),
              field: 'otherServiceIncome',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
              minWidth: 220
            },
            {
              headerName: this.$t('dealer_finance.newVehicleRetail.otherServiceCost'),
              field: 'otherServiceCost',
              // editable: this.editable,
              // cellEditorParams: {
              //   rules: { type: 'number', message: this.$t('validate.notNumber') }
              // },
              // valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
              minWidth: 220
            },
            {
              headerName: this.$t('dealer_finance.newVehicleRetail.includingCashDiscountAmount'),
              field: 'includingCashDiscountAmount',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
              minWidth: 220
            },
            {
              headerName: this.$t('dealer_finance.newVehicleRetail.includingFuelGiftCardDiscountAmount'),
              field: 'includingFuelGiftCardDiscountAmount',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
              minWidth: 220
            },
            {
              headerName: this.$t('dealer_finance.newVehicleRetail.includingShoppingGiftCardDiscountAmount'),
              field: 'includingShoppingGiftCardDiscountAmount',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
              minWidth: 220
            },
            {
              headerName: this.$t('dealer_finance.newVehicleRetail.includingMaintenanceServiceDiscountAmount'),
              field: 'includingMaintenanceServiceDiscountAmount',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
              minWidth: 220
            },
            {
              headerName: this.$t('dealer_finance.newVehicleRetail.includingOtherDiscountAmount'),
              field: 'includingOtherDiscountAmount',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
              minWidth: 220
            },
            {
              headerName: this.$t('dealer_finance.newVehicleRetail.salesPresent'),
              field: 'salesGiftCost',
              editable: this.editable,
              cellEditorParams: {
                rules: { type: 'number', message: this.$t('validate.notNumber') }
              },
              valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
              minWidth: 220
            },
            {
              headerName: this.$t('dealer_finance.newVehicleRetail.grossProfit3'),
              field: 'maoriThree',
              // editable: this.editable,
              // cellEditorParams: {
              //   rules: { type: 'number', message: this.$t('validate.notNumber') }
              // },
              // valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
              valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
              minWidth: 220
            }
          ]
        },
        {
          headerName: this.$t('dealer_finance.newVehicleRetail.formulaArea'),
          children: [
            {
              headerName: this.$t('dealer_finance.newVehicleRetail.jbOrLh'),
              field: 'brand',
              valueFormatter: params => {
                return this.$getDictLabel({ type: 'tt_brand', value: params.value })
              },
              minWidth: 120
            },
            {
              headerName: this.$t('dealer_finance.newVehicleRetail.domesticOrImport'),
              field: 'origin',
              valueFormatter: params => {
                return this.$getDictLabel({ type: 'tt_origin', value: params.value })
              },
              minWidth: 120
            }
          ]
        },
        {
          headerName: this.$t('field.operation'),
          width: 100,
          pinned: 'right',
          hide: !this.editable,
          suppressSizeToFit: true,
          cellRenderer: 'Operation'
        }
      ]
    }
  },
  watch: {
    editable (val) {
      this.$nextTick(() => {
        this.$refs.nvrGrid.redrawGrid(this.columnDefs)
      })
    }
  },
  provide () {
    return {
      handleDataSearch: this.handleDataSearch,
      handleSaveClick: this.handleSaveClick,
      handleDeleteClick: this.handleDeleteClick
    }
  },
  beforeMount () {
    this.frameworkComponents = { Operation: Operation }
  },
  methods: {
    handleDataSearch () {
      const params = Object.assign({}, this.$utils.parseQueryCondition(this.searchModel, this.searchFormField))
      params.filter.push({ left: 'head.id', operate: '=', right: this.detailData.id })
      params.page = { orderBy: 'sort' }
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      this.$axios
        .post(BASEURL.list, params)
        .then(resp => {
          const respData = resp.data
          this.tableData = respData.list
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    handleAddClick () {
      this.tableData.push({})
      this.$nextTick(() => {
        this.$refs.nvrGrid.focusOnCell(this.tableData.length - 1, 'vin')
      })
    },
    handleDeleteClick (row) {
      if (!row.id) return this.handleDataSearch()
      this.$msgbox({
        title: this.$t('tip.warning'),
        message: this.$t('tip.confirmDelete'),
        type: 'warning',
        showCancelButton: true,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            this.$axios
              .post(BASEURL.delete, { id: row.id })
              .then(resp => {
                this.$message({ type: 'success', message: this.$t('tip.deleteSuccess') })
                done()
              })
              .finally(() => {
                instance.confirmButtonLoading = false
              })
          } else done()
        }
      })
        .then(() => {
          this.handleDataSearch()
        })
    },
    handleSaveClick (row) {
      this.$refs.nvrGrid.validate(row, status => {
        if (status) {
          const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })
          let submitData = this.$_.cloneDeep(row.data)
          submitData.head = { id: this.detailData.id }
          submitData.otherServiceCost = submitData.includingCashDiscountAmount + submitData.includingFuelGiftCardDiscountAmount + submitData.includingShoppingGiftCardDiscountAmount + submitData.includingMaintenanceServiceDiscountAmount + submitData.includingOtherDiscountAmount
          this.$axios
            .post(row.data.id ? BASEURL.update : BASEURL.save, submitData)
            .then(resp => {
              this.currentShowFlag = false
              this.$message({ type: 'success', message: this.$t('tip.saveSuccess') })
              this.handleDataSearch()
            })
            .finally(() => {
              loadingFlag.close()
            })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.page__wrapper {
  width: 100%;
  height: 100%;
}
</style>
